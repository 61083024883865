import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import Login from '../pages/Login';

const AppRoute = () => (
    <Router>
        <Routes>
            <Route path="/login" element={<Login />}/>
            <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
    </Router>
)

export default AppRoute;
