import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { light } from '@sumup/design-tokens';
import { BaseStyles, ModalProvider } from '@sumup/circuit-ui';

import AppRoute from '../../routes';

const App = () => {
    return (
        <ThemeProvider theme={light}>
            <BaseStyles />
            <ModalProvider>
                <AppRoute />
            </ModalProvider>
        </ThemeProvider >
    );
}

export default App;
