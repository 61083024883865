import styled from '@emotion/styled';

export const Header = styled('header')`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    padding: 0px;
    background: #FFFFFF;
    height: 56px;
    border-bottom: 1px solid #CCCCCC;
    justify-content: space-between;

    a.logo {
      height: 56px;
      display: flex;
      align-items: center;
      padding: 0 16px;
    }

    button.help {
      border: none;
      background-color: transparent;
      border-left: 1px solid #ccc;
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 13px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
      }

      @media (max-width: 480px) {
        span {
          display: none;
        }
      }

      :hover {
        background-color: rgb(245, 245, 245);
      }
    }

    svg {
      color: #000;
      height: 24px;
    }
`;
