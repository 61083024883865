import { Card, Body, CardHeader, Headline } from '@sumup/circuit-ui';
import QRCode from "react-qr-code";
import { BrowserView, AndroidView, IOSView, isIOS, isMobile } from 'react-device-detect';
import {
    Center,
    Container,
    ContainerLinks,
    Footer,
    PageTitle,
    PoweredByAndLogo,
    QrCodeContainer,
    QrCodeMargin,
    TimeToCompleteWarning,
} from './Login.styles';
import { AndroidDownloadButton, IOSDownloadButton } from '../../components'
import TopNavigation from '../../components/TopNavigation';
import { Remove, Secure, SumUpLogo, Time } from '@sumup/icons';

const androidDeeplinkLoginEnv = process.env.REACT_APP_ANDROID_DEEPLINK_LOGIN_URL
const iOSDeeplinkLoginEnv = process.env.REACT_APP_IOS_DEEPLINK_LOGIN_URL
const interactionId = new URLSearchParams(location.search).get('interactionId')

const { location: { search } } = window

const isValidUrlToRedirect = (query) => {
    if (!query) return false;

    const params = new URLSearchParams(query);

    const hasRouteWithOpenBanking = params.get('route') == 'openbanking';
    const hasMethodWithLogin = params.get('method') == 'login';
    const hasValidInteractionId = /^([A-Za-z0-9_-]){21}$/.test(interactionId);

    return hasRouteWithOpenBanking && hasMethodWithLogin && hasValidInteractionId;
}

const DeeplinkLoginURL = (deeplinkLoginEnv) => (
    deeplinkLoginEnv + '&interactionId=' + interactionId
);

const BrowserContent = () => (
    <BrowserView>

        {isValidUrlToRedirect(search) ?
            <>
                {isMobile ?
                    isIOS ?
                        location.replace(DeeplinkLoginURL(iOSDeeplinkLoginEnv))
                        : location.replace(DeeplinkLoginURL(androidDeeplinkLoginEnv))
                    : null}
                <CardHeader>
                    <Headline
                        noMargin
                        size='three'
                    >
                        Solicitação de pagamento iniciada
                    </Headline>
                    <Secure />
                </CardHeader>
                <Body noMargin>
                    Aponte a câmera do seu smartphone para o QR Code abaixo e continue o pagamento através do seu aplicativo do SumUp Bank.
                </Body>
                <QrCodeContainer>
                    <QrCodeMargin>
                        <QRCode
                            data-testid="android-qrcode-redirect"
                            size={210}
                            value={DeeplinkLoginURL(androidDeeplinkLoginEnv) + '&origin=web'}
                        />
                    </QrCodeMargin>
                    <QrCodeMargin>
                        <QRCode
                            data-testid="ios-qrcode-redirect"
                            size={210}
                            value={DeeplinkLoginURL(iOSDeeplinkLoginEnv) + '&origin=web'}
                        />
                    </QrCodeMargin>
                </QrCodeContainer>
            </> :
            <>
                <CardHeader>
                    <Headline
                        noMargin
                        size='three'
                    >
                        Ops, não foi possível completar sua transação!
                    </Headline>
                    <Remove />
                </CardHeader>
                <Body bold={true}>
                    Por favor, volte para iniciadora e tente novamente.
                </Body>
            </>
        }

        <Center>
            <AndroidDownloadButton height={60} />
            <IOSDownloadButton height={61} />
        </Center>
    </BrowserView>
);

const Login = () => (
    <>
        <TopNavigation />
        <Container>
            <PageTitle size='two'>Pagamento via Open Finance</PageTitle>
            <Card>
                <BrowserContent />
                <Center>
                    <AndroidView>
                        <Body noMargin>
                            Baixe o SumUp Bank para continuar
                        </Body>
                        <AndroidDownloadButton height={60} />
                    </AndroidView>
                    <IOSView>
                        <Body noMargin>
                            Baixe o SumUp Bank para continuar
                        </Body>
                        <IOSDownloadButton height={60} />
                    </IOSView>
                </Center>
            </Card>

            <TimeToCompleteWarning>
                <Time />
                <span>Você possui <strong>5 minutos</strong> para finalizar o pagamento.</span>
            </TimeToCompleteWarning>

            {/* <BackToStarter>
                <ArrowLeft />
                <span>Voltar para a iniciadora do pagamento</span>
            </BackToStarter> */}

            <Footer>
                <PoweredByAndLogo>
                    <Body noMargin>Powered by</Body>
                    <a className='logo' href="https://sumup.com.br/">
                        <SumUpLogo width='80px' color='#000' />
                    </a>
                </PoweredByAndLogo>
                <ContainerLinks>
                    <a href="https://sumup.com.br/transparencia/">
                        <Body>Transparência</Body>
                    </a>
                    <a href="https://sumup.com.br/privacidade/">
                        <Body>Privacidade</Body>
                    </a>
                    <a href="https://sumup.com.br/termos/">
                        <Body>Termos e condições</Body>
                    </a>
                    <a href="https://sumup.com.br/transparencia/cookies/">
                        <Body>Política de Cookies</Body>
                    </a>
                </ContainerLinks>
                <Body
                    size='two'
                    variant='subtle'
                >
                    SumUp Instituição de Pagamento Brasil Ltda. Todos os direitos reservados. (CNPJ: 16.668.076/0001-20) payleven é uma solução SumUp. Rua Gilberto Sabino, 215 - Pinheiros, São Paulo - SP, 05425-020. Telefone: 4003-6338
                </Body>
            </Footer>
        </Container>
    </>
);

export default Login;
