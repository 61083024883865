export default ({ height = 115 }) =>
    <a
        title="Clique para baixar o SumUp Bank no Google Play"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.sumup.bank.mobile.user&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    >
        <img
            alt="Disponível no Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
            style={{ height: height }}
        />
    </a>
