import styled from '@emotion/styled';
import { Headline } from '@sumup/circuit-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 90%;
  max-width: 560px;
  margin: 0 auto;
`;

export const Center = styled.div`
  text-align: center;
`

export const QrCodeContainer = styled.div`
  padding: 24px 0px 14px;
  text-align: center;
`

export const QrCodeMargin = styled.div`
  display: inline-block;
  padding: 16px 16px 16px;
`

export const PageTitle = styled(Headline)`
  padding: 32px 0;
  margin-bottom: 0px;
`

export const TimeToCompleteWarning = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background: #F5F5F5;
  border-radius: 16px;
  margin-bottom: 32px;
  span {
    margin-left: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: center;

    strong {
      font-weight: bold;
    }
  }
`

export const ContainerLinks = styled.div`
  padding: 44px 0;

  a {
    text-decoration: none;
    color: #000;
  }

  p:hover {
    color: #3063E9;
    text-decoration: underline;
  }
`

export const Footer = styled.footer`
  padding: 64px 0 64px;
`

export const PoweredByAndLogo = styled.div`
  display: flex;
  align-items: start;

  p {
    margin-right: 7px;
  }
`

export const BackToStarter = styled.button`
  border: 2px solid #E6E6E6;
  background-color: transparent;
  padding: 21px 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: #3063E9;
    margin-right: 17px;
  }

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
  }

  :hover {
    background-color: rgb(245, 245, 245);
  }
`
