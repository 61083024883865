import { Headline, Body, useModal, Button, Image } from '@sumup/circuit-ui';
import { Help, Secure } from '@sumup/icons';
import ImageHelpStep1 from '../../assets/images/help_step_1.png';
import ImageHelpStep2 from '../../assets/images/help_step_2.png';
import ImageHelpStep3 from '../../assets/images/help_step_3.png';
import { BodyAllowStrong, ContainerButtonClose, HelpStep, InlineElementsWithPadding, SafeEnvironmentWarning } from './HelpModal.styles';

const ButtonHelpModal = () => {
    const { setModal } = useModal();

    const handleClick = () => {
        setModal({
            children: ({ onClose }) => {
                return (
                    <>
                        <Headline size='three'>Como funciona?</Headline>
                        <BodyAllowStrong>Você acabou de iniciar uma solicitação de pagamento via Open Finance e precisará concluir o pagamento através do aplicativo do SumUp Bank, em até <strong>5 minutos</strong>. Basta seguir os passos para concluir seu pagamento:</BodyAllowStrong>

                        <InlineElementsWithPadding>
                            <HelpStep>
                                <Image
                                    src={ImageHelpStep1}
                                />
                                <Body size="two" variant="highlight">Escaneie o QR Code com a câmera do seu celular</Body>
                            </HelpStep>
                            <HelpStep>
                                <Image
                                    src={ImageHelpStep2}
                                />
                                <Body size="two" variant="highlight">Abra o aplicativo do SumUp Bank e faça o log in</Body>
                            </HelpStep>
                            <HelpStep>
                                <Image
                                    src={ImageHelpStep3}
                                />
                                <Body size="two" variant="highlight">Agora confira os dados e conclua o pagamento</Body>
                            </HelpStep>
                        </InlineElementsWithPadding>
                        <SafeEnvironmentWarning>
                            <Secure />
                            <span>Ambiente seguro e criptografado pelo Banco Central</span>
                        </SafeEnvironmentWarning>
                        <ContainerButtonClose>
                            <Button
                                children='Ok, entendi'
                                type='button'
                                variant='primary'
                                onClick={onClose}
                            />
                        </ContainerButtonClose>
                    </>
                )
            },
            closeButtonLabel: 'Fechar modal',
        });
    };

    return (<button className='help' onClick={handleClick}>
        <Help />
        <span>Ajuda</span>
    </button>);
}

export default ButtonHelpModal;
