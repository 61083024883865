import styled from '@emotion/styled';
import { Body, InlineElements } from '@sumup/circuit-ui';

export const BodyAllowStrong = styled(Body)`
    strong {
      font-weight: bold;
    }
`;

export const ContainerButtonClose = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const SafeEnvironmentWarning = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background: #F5F5F5;
  border-radius: 16px;
  margin-bottom: 24px;

  span {
    margin-left: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: center;
  }
`

export const HelpStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  img {
    width: 55%;
  }

  strong {
    text-align: center;
  }

  @media (max-width: 480px) {
    flex-direction: row;
    padding-bottom: 30px;

    img {
      width: 35%;
      margin-right: 32px;
    }

    strong {
      text-align: left;
    }
  }
`

export const InlineElementsWithPadding = styled(InlineElements)`
  padding: 16px 0px;

  @media (max-width: 480px) {
    padding: 16px 16px;
  }
`;
