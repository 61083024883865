import { SumUpLogo, Help } from '@sumup/icons';
import ButtonHelpModal from '../HelpModal/HelpModal';
import { Header } from './TopNavigation.styles';

const TopNavigation = () => {

  return (
    <Header>
      <a className='logo' href="https://sumup.com.br/">
        <SumUpLogo />
      </a>
      <ButtonHelpModal />
    </Header>
  );
}

export default TopNavigation;
